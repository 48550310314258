<template>
  <div>
    <b-row>
      <b-col lg="12" class="my-1 mb-3">
        <b-button size="md" variant="danger" class="text-uppercase" @click="onClaimsImportClick"
          >Import Claim Master</b-button
        >
      </b-col>
    </b-row>

    <!-- Import Approval Codes Modal -->
    <b-modal id="claimsImportModal" ref="claimsImportModal" hide-header hide-footer>
      <h3 class="mb-4">Import claim master</h3>
      <b-form
        @submit.prevent="onClaimsImportSubmit"
        class="av-tooltip tooltip-label-bottom"
      >
        <b-row>
          <b-col class="col-12 mb-4">
            <b-input-group>
              <label class="pl-0 mb-2 col-12">Select file: </label>
              <b-form-file
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="onFileChange"
              ></b-form-file>
            </b-input-group>
          </b-col>
          <div class="col-12">
            <b-button variant="primary" size="lg" class="float-right" type="submit">
              <span class="label">Save</span>
            </b-button>
          </div>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      importFile: null
    };
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      const file = files[0];
      this.importFile = file;
    },
    onClaimsImportClick() {
      this.$refs["claimsImportModal"].show();
    },
    async onClaimsImportSubmit() {
      this.$refs["claimsImportModal"].hide();
      let formData = new FormData();
      formData.append("file", this.importFile);

      await this.$store
        .dispatch("submitClaimsFile", formData)
        .then((response) => {
          this.processing = false;
          this.$notify(
            response.status,
            "Claim master is imported.",
            response.message,
            {
              duration: 2000,
              permanent: false,
            }
          );
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.stats-table {
  height: auto;
}

.b-table-sticky-header {
  max-height: 500px;
}
</style>